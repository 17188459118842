import React from "react"
import Masthead from '../components/Masthead'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import BlockSimple from '../components/BlockSimple'
import "../components/BookTrip/styles.css"


const AboutPage = () => (
  <Layout>
    <div>
      <SEO title="Costa Rica Best Choice" />
      <div className="masthead__wrapper">
        <div className="masthead-book">
        </div>
      </div>

      <div  className="layout-max-width">
        <div className="block-simple__bg">
          <div className="block-simple__wrapper">
            <h1 className="block-simple__heading">Book your trip</h1>
          </div>
        </div>
        <div className="book-trip">
          <div>
            <form method="post"
              action="https://formbucket.com/f/buk_T3NtvU5RG9M0pNpV3uDlhCoX">
              <div className="book-trip__field">
                <label type="text">Name *</label>
                <input required name="name" />
              </div>
              <div className="book-trip__field">
                <label type="email">Email *</label>
                <input required name="email" />
              </div>
              <div className="book-trip__field">
                <label type="email">Service to request *</label>
                <input label="Subject" name="subject" />
              </div>
              <div className="book-trip__field input__holder">
                <label className="input__label">Message</label>
                <textarea className="input__field" name="message" />
              </div>
              <button className="book-trip__button" type="submit">
                Send
              </button>
            </form>
          </div>

          <div>
            <h2>Costa Rica Best Choice</h2>
            <p>Tamarindo, Guanacaste, Costa Rica</p>
            <h2>Customer Service:</h2>
            <p>Tel: +506 8838 1612</p>
            <p>E-mail: info@costaricabestchoice.com</p>
          </div>
        </div>
      </div>


    </div>
  </Layout>
)

export default AboutPage
